import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils/breakpoints';
import { font } from '@prototyp/gatsby-plugin-gumball/utils/font';
import { flexCenter, scrollbar } from 'style/global.styles';

export const root = css`
  margin-top: ${spacing(32)};
`;

export const label = css`
  color: hsl(var(--color-grayscale-6));
  margin-bottom: ${spacing(4)};

  span {
    color: hsl(var(--color-grayscale-6));
  }
`;

export const labelLight = css`
  ${label};

  span {
    color: hsl(var(--color-textLight-7));
  }
`;

export const bottomLabel = css`
  ${font.size.tiny};
  color: hsl(var(--color-textLight-7));
  display: flex;
  justify-content: space-between;
`;

export const sizeError = css`
  ${bottomLabel};
  color: hsl(var(--color-warning-6));
`;

export const input = css`
  margin-bottom: ${spacing(4)};
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(221, 225, 227, 0.1);
  width: 100%;
  height: ${spacing(36)};
  border-radius: ${spacing(3)};
  transition: border 0.2s;
  outline: none;
  ${flexCenter};

  &:hover,
  &:focus,
  &:active {
    border: 1px solid rgba(221, 225, 227, 0.3);
  }

  &:hover {
    cursor: pointer;
  }

  svg {
    height: ${spacing(16)};
    width: ${spacing(16)};

    path {
      fill: hsl(var(--color-textLight-7));
    }
  }

  @media ${breakpoints.medium} {
    height: ${spacing(136)};

    svg {
      height: ${spacing(32)};
      width: ${spacing(32)};
    }
  }
`;

export const inputLight = css`
  ${input};
  border: 1px solid hsl(var(--color-borderUltraLight-9));
  background: transparent;

  svg {
    path {
      fill: hsl(var(--color-text-1));
    }
  }

  &:hover,
  &:focus,
  &:active {
    border: 1px solid hsl(var(--color-borderLight-8));
  }
`;

export const error = css`
  ${font.size.small};
  margin-top: ${spacing(8)};

  span {
    color: hsl(var(--color-warning-6));
  }
`;

export const attachments = css`
  max-height: 100px;
  overflow-y: scroll;
  ${scrollbar};
  margin-top: ${spacing(12)};
`;
